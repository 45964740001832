<template>
  <CabinetPage :preloader="preloader">
    <div class="container-fluid" >
      <div class="row">
        <div class="col-md-12" style="margin-top: 90px; margin-bottom: 40px;">
          <h4 class="mx-title">Ansprechpartner</h4>
        </div>
      </div>

      <ContactPerson/>

    </div>
  </CabinetPage>
</template>

<script>
import CabinetPage from "@/components/main/CabinetPage";
import ContactPerson from "@/components/contact_person/ContactPerson";
export default {
  name: "MyContactPersonView",
  components: {ContactPerson, CabinetPage},
  data() {
    return {
      preloader: true,
    }
  },
  created() {
    setTimeout(() => {this.preloader = false}, 300)
  }
}
</script>

<style scoped>

</style>