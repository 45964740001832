<template>

  <div v-if="contact_persons.length > 0" class="row">
      <div v-for="contact in contact_persons" :key="contact.employee_uid" class="col-md-12 col-lg-6 col-xl-6 col-xxl-4">
        <div class="mx-card">
          <div style="display: flex;">
            <img :src="contact.avatar !== null  ? options.avatar_url + contact.avatar : '/assets/images/user_new.png'" width="100" class="img-fluid img-thumbnail">
            <div style="display: flex; flex-direction: column; flex-grow: 1; padding: 0 15px 0 25px;">
              <strong style="font-size: 18px; font-weight: 500; color: #333333; border-bottom: 1px solid #cccccc; padding-bottom: 3px; margin-bottom: 0;">
                {{ contact.first_name }} {{ contact.last_name }}
              </strong>
              <div >
                <strong style="font-weight: 500; font-size: 16px; width: 100%; display: inline-block; padding: 0;">
                  {{ contact.position }}
                </strong>
              </div>

              <div class="mt-auto">
                <span style="margin-right: 5px;">Email: </span>
                <a :href="`mailto:${contact.contact_email}`">{{ contact.contact_email }}</a>
              </div>
              <div class="mb-1">
                <span style="margin-right: 5px;">Telefon:</span>
                <strong>{{ contact.contact_phone }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

</template>

<script>
export default {
  name: "ContactPerson",
  data() {
    return {
      contact_persons: [],
    }
  },
  mounted() {
    this.$store.dispatch('getContactPersons').then(() => {
      this.contact_persons = this.$store.getters.contact_persons;
    })
  }
}
</script>
